import React, { useEffect } from "react";
import { navigate } from "gatsby";

const RedirectPage = () => {
  useEffect(() => {
    navigate("/chardham-by-helicopter");
  }, []);

  return <div>Redirecting...</div>;
};

export default RedirectPage;
